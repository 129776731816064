import StoreContainer from "../store";
import __ from "../services/__";
import IdbService from "../services/IdbService";
import _ from 'lodash';

function getTimeTest() {
    let ARRIVAL = StoreContainer.getAnswer('ARRIVAL');
    let START = StoreContainer.data.started;

    let arrivalDate = new Date(ARRIVAL);

    let now = new Date(START);

    let date8th = new Date('2021-08-08T00:01');

    // To calculate the no. of days between two dates
    let DifferenceInHours = (now.getTime() - arrivalDate.getTime()) / (1000 * 3600 );

    if(arrivalDate.getTime() > date8th.getTime()) {
        return 'MINULOST';
    }

    if(DifferenceInHours > 3) {
        return 'SOUCASNOST';
    }

    return 'BUDOUCNOST';
}

const proParseHooks = {

    page102: (page) => {

        if(
            !(
                StoreContainer.data.answers['Kemp' ] === '1' ||
                ["1", "2", "3", "4", "6" ].includes(StoreContainer.data.answers['T0'])
            )
        ) {
            delete(page.questions['Tent'])
            page.next = 104;
        }

        return page;
    },

    page180: (page) => {

        if(
            !(
                StoreContainer.data.answers['Ekok' ] !== '1' &&
                ["1", "3", "4", "5", "6", "7"].includes(StoreContainer.data.answers['T0'])
            )
        ) {
            delete(page.questions['K1'])
        }

        return page;
    },


    page183: (page) => {

        if(
            !(
                (
                    StoreContainer.data.answers['K1' ] === '1' &&
                    ["1", "3", "4", "5", "6", "7"].includes(StoreContainer.data.answers['T0'])
                ) ||
                StoreContainer.data.answers['T0'] === "2" ||
                (
                    StoreContainer.data.answers['Kemp' ] === '1' &&
                    ["8", "9", "10", "11"].includes(StoreContainer.data.answers['T0'])
                )
            )
        ) {
            delete(page.questions['K5'])
        }

        return page;
    },

    page201: (page) => {

        if(
            !(
                StoreContainer.data.answers['Ekok' ] !== '1' ||
                StoreContainer.data.answers['T0' ] !== '2'
            )
        ) {
            delete(page.text)
        }

        if(StoreContainer.data.answers['Wa' ] != '1') {
            delete(page.questions['Q2a']['subQuestions']['a']);
            delete(page.questions['Q2b']['subQuestions']['a']);
        }

        if(StoreContainer.data.answers['Wc' ] != '1') {
            delete(page.questions['Q2a']['subQuestions']['c']);
            delete(page.questions['Q2b']['subQuestions']['c']);
        }

        if(StoreContainer.data.answers['We' ] != '1') {
            delete(page.questions['Q2a']['subQuestions']['e']);
            delete(page.questions['Q2b']['subQuestions']['e']);
        }

        if(StoreContainer.data.answers['Wg' ] != '1') {
            delete(page.questions['Q2a']['subQuestions']['g']);
            delete(page.questions['Q2b']['subQuestions']['g']);
        }

        if(
            _.size(page.questions['Q2a']['subQuestions']) === 0
        ) {
            delete(page.text)
            delete(page.questions['Q2a'])
            delete(page.questions['Q2b'])
        }

        return page;
    },

    page206: (page) => {
        if (StoreContainer.data.answers['COUNTRY'] !== 'cz') {
            page.next = 300
        }

        return page;
    },

    page501: (page) => {
        IdbService.markFinished(StoreContainer.data.currentId);

        return page;
    },

    page400: (page) => {
        console.log(StoreContainer.data.answers['LANG']);

        if(StoreContainer.data.answers['LANG'] === 'en'){
            delete(page.questions['EDUCAT']['answers'][7]);
            delete(page.questions['EDUCAT']['answers'][8]);
            delete(page.questions['EDUCAT']['answers'][9]);
            delete(page.questions['EDUCAT']['answers'][10]);
            delete(page.questions['EDUCAT']['answers'][11]);
        }

        return page;
    }

    /*
    page103: (page) => {
        let timeTest  = getTimeTest()

        StoreContainer.data.answers['TIMETEST'] = timeTest;

        if(!(timeTest === 'SOUCASNOST' || timeTest === 'MINULOST')) {
            page.questions = [];
        }

        return page;
    },

    page104: (page) => {
        let timeTest  = getTimeTest()

        StoreContainer.data.answers['TIMETEST'] = timeTest;

        if(!(timeTest === 'SOUCASNOST' || timeTest === 'BUDOUCNOST')) {
            delete page.questions.Q3.subQuestions['b'];
        }

        return page;
    },

    page108: (page) => {
        let timeTest  = getTimeTest()

        StoreContainer.data.answers['TIMETEST'] = timeTest;

        if(!(timeTest === 'SOUCASNOST' || timeTest === 'BUDOUCNOST')) {
            delete page.questions.Q8.subQuestions['b'];
        }

        return page;
    },

    page200: (page) => {

        // introtext
        let lang = StoreContainer.data.currentLanguage;

        let GROUP = StoreContainer.getAnswer('GROUP');

        page.nonTranslatedText = __(page.text, lang) + __(page['text' + GROUP], lang)

        page.text = '';

        // adding price to question
        let cena = StoreContainer.getAnswer('CENA');

        page.questions.Q14.nonTranslatedText = __(page.questions.Q14.text, lang);

        page.questions.Q14.nonTranslatedText = page.questions.Q14.nonTranslatedText.replace('[CENA]', cena)

        page.questions.Q14.text = '';

        return page;
    },

    page201: (page) => {

        let lang = StoreContainer.data.currentLanguage;

        // adding price to question
        let prct = StoreContainer.getAnswer('PRCT');

        let food = StoreContainer.getAnswer('FOOD');

        page.questions.Q16.nonTranslatedText = __(page.questions.Q16['text' + food], lang);

        console.log(page.questions.Q16.nonTranslatedText)

        page.questions.Q16.nonTranslatedText = page.questions.Q16.nonTranslatedText.replace('[PRCT]', prct)

        return page;
    },

    page407: (page) => {

        let lang = StoreContainer.data.currentLanguage;

        let guide = StoreContainer.getAnswer('GUIDE', 'int');
        let Q18 = StoreContainer.getAnswer('Q18', 'int');

        page.nonTranslatedText = __('p407.base', lang)

        if(guide === 1 && (Q18 === 2 || Q18 === 88)) {
            page.nonTranslatedText += __('p407.guide', lang)
        }

        return page;
    },

    page500: (page) => {

        let lang = StoreContainer.data.currentLanguage;

        let guide = StoreContainer.getAnswer('GUIDE', 'int');
        let Q18 = StoreContainer.getAnswer('Q18', 'int');

        if(guide !== 1 || Q18 !== 2) {
            delete page.questions['DB4'];
        }

        return page;
    },
    */


}

export default proParseHooks;

import http from "./Http";
import IdbService from "./IdbService";
import _ from 'lodash'
import mitt from "./Mitt";


let translator  = {
    strings: {},
    downloadedAt: {},
    version: {},

    selectedLanguage: 'cz',

    downloadTranslations() {
        _.forEach(window.surveyConfig.languages, (language) => {
            http.get('/strings/' + language).then((response) => {
                IdbService.setTranslations(
                    language,
                    response.data
                ).then(() => {
                    IdbService.getTranslations(language)
                        .finally(() => {
                            mitt.emit('displayStoreStatus')
                        });
                })
            })
        });
    },

    setStrings(language, data) {
        this.strings[language] = data.strings;

        this.downloadedAt[language] = data.downloadedAt;

        this.version[language] = data.version;
    },

    getVersion(language) {
        return this.version[language] !== 'undefined' ? this.version[language] : false;
    },

    getDownloadTime(language) {
        return typeof this.downloadedAt[language] !== 'undefined' ? this.downloadedAt[language] : false;
    },

    translate(string, language) {

        const langArr = string.split(".");

        if(typeof this.strings[language] === 'undefined') {
            return '<span style="color: lightcoral;">[lang missing]</span>';
        }

        if(langArr.length === 2) {
            if(
                typeof this.strings[language][langArr[0]] === 'undefined'
                || typeof this.strings[language][langArr[0]][langArr[1]] === 'undefined'
            ) {
                return '<span style="color: lightcoral;">['+ string + ']</span>';
            }

            return this.strings[language][langArr[0]][langArr[1]];
        }

        if(
            typeof this.strings[language][langArr[0]] === 'undefined'
        ) {
            return '<span style="color: lightcoral;">['+ string + ']</span>';
        }


        return this.strings[language][langArr[0]];
    }
}


export default translator

